cs.ns('app.ui.widget.list')
app.ui.widget.list.view = cs.clazz({
    extend:   app.fw.abstract.view,
    dynamics: {
        markupName: 'widget-list'
    },
    protos:   {

        registerDataBindings () {
            this.observeOwnModel("data:selectedEntry", (ev, entry) => {
                const uielement     = $(`#${entry}`, this.ui)
                const parentPadding = $(this.ui).parent().css("padding-top")
                const padding       = parentPadding ? parseInt(parentPadding, 10) : 0
                const offset        = uielement.position()
                if (offset && typeof offset.top === "number")
                    setTimeout(() => {
                        $(".simplebar-content", this.ui).animate({ scrollTop: $(".simplebar-content", this.ui).scrollTop() + uielement.position().top - padding })
                    }, 100)

            })
        }
    }
})